import axios from 'axios';

const API_BASE_URL = 'https://bkpost.us/lens';

export const loginUser = async (credentials) => {
  const response = await fetch(`${API_BASE_URL}/api/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  });
  if (!response.ok) {
    throw new Error('Login failed');
  }
  return response.json();
};

export const registerUser = async (userData) => {
  const response = await fetch(`${API_BASE_URL}/api/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  });
  if (!response.ok) {
    throw new Error('Registration failed');
  }
  return response.json();
};

export const createEvent = async (eventData, token) => {
  const response = await fetch(`${API_BASE_URL}/api/events`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(eventData),
  });
  if (!response.ok) {
    throw new Error('Failed to create event');
  }
  return response.json();
};

export const getEvent = async (eventId) => {
  const response = await axios.get(`${API_BASE_URL}/api/events/${eventId}`);
  return response.data;
};

export const uploadPhoto = async (eventId, photoUrl) => {
  const response = await axios.post(`${API_BASE_URL}/api/photos`, { eventId, url: photoUrl });
  return response.data;
};

export const getEventPhotos = async (eventId) => {
  const response = await axios.get(`${API_BASE_URL}/api/events/${eventId}/photos`);
  return response.data;
};