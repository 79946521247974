import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getEvent, getEventPhotos } from '../api';

const EventView = () => {
  const [event, setEvent] = useState(null);
  const [photos, setPhotos] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const eventData = await getEvent(id);
        setEvent(eventData);
        const photosData = await getEventPhotos(id);
        setPhotos(photosData);
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };

    fetchEventData();
  }, [id]);

  if (!event) {
    return <div>Loading...</div>;
  }

  return (
    <div className="event-view">
      <h2>{event.name}</h2>
      <p>Date: {new Date(event.date).toLocaleDateString()}</p>
      <img src={event.qrCode} alt="Event QR Code" />
      <h3>Photos</h3>
      <div className="photo-grid">
        {photos.map((photo) => (
          <img key={photo._id} src={photo.url} alt="Event" />
        ))}
      </div>
    </div>
  );
};

export default EventView;