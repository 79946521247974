import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { uploadPhoto } from '../api';

const PhotoUpload = () => {
  const [file, setFile] = useState(null);
  const { id } = useParams();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) return;

    const formData = new FormData();
    formData.append('photo', file);

    try {
      await uploadPhoto(id, formData);
      alert('Photo uploaded successfully!');
      setFile(null);
    } catch (error) {
      console.error('Error uploading photo:', error);
      alert('Failed to upload photo. Please try again.');
    }
  };

  return (
    <div className="photo-upload">
      <h2>Upload Photo</h2>
      <form onSubmit={handleSubmit}>
        <input type="file" accept="image/*" onChange={handleFileChange} />
        <button type="submit" disabled={!file}>
          Upload
        </button>
      </form>
    </div>
  );
};

export default PhotoUpload;